<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Keeper changes</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="tag is-warning" v-if="changes"
            >{{ changes }} previous keeper(s)</span
          >
          <span class="has-text-success" v-else>0 keeper changes</span>
        </p>
      </div>
    </div>
    <div v-if="changes">
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <td>Dates of previous change</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <p>{{ formatDate(item.dateOfLastKeeperChange) }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckKeeperChange',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    changes() {
      return this.data.keeperChangesCount
    },
    items() {
      return this.data.keeperChanges1
    }
  },
  methods: {
    formatDate
  }
}
</script>
